import Request from "@/components/request/js/index.js";
import { config } from "@/components/request/js/config.js";
export default {
  ask(data) {
    return Request().post("/api/ai/ask", {
      header: {
        contentType: "application/json",
      },
      data: {
        ...data,
        authKey: "G3qaPwr!iJUw34DG",
      },
    });
  },
  claude(data) {
    return Request().post("/api/ai/claude", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //信息流
  getInfoFlow(page, filters) {
    return Request().post("/api/paper/explore", {
      header: {
        contentType: "application/json",
      },
      data: {
        page: page,
        ...filters,
      },
    });
  },
};
